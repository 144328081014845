import { SlackOAuthClient } from "messaging-api-slack"
import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "./../components/seo"
const p404 = () => {
  useEffect(() => {
    if (process.env.NODE_ENV == "production" && typeof window !== "undefined" && window.location.href.includes("france-cadre.fr")) {
      const client = new SlackOAuthClient({
        accessToken: "xoxp-12970109927-12994999092-596958357282-d0b1be921f8a7171b658913cd5e2b9ef",
      })
      let url = typeof window !== "undefined" ? window.location.href : ""
      client
        .callMethod("chat.postMessage", {
          channel: "alerts-cadres",

          blocks: JSON.stringify([
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: "Erreur 404 : France-Cadre sur la page " + url,
              },
            },
          ]),

          as_user: false,
          username: "France-cadres",
        })
        .then(() => {})
    }
  }, [])

  return (
    <Layout>
      <SEO title={`Erreur 404`} description="Cette page n'existe pas" />
      <div style={{ textAlign: "center" }}>
        <h1>ERREUR 404</h1>
        <h1>Désolé, Cette page n’existe pas.</h1>
        <h1>Rendez-vous sur l’une de nos autres pages pour poursuivre votre visite.</h1>
      </div>
    </Layout>
  )
}

export default p404
